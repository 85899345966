






























































































































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({ name: 'LandingBlock' })
export default class LandingBlock extends Vue {
  @Prop()
  small!: boolean
  three_month_price_for_address = '3 980'
  three_month_price = '1 990'
  premium_price = '1 600'
  premium_price_banner = '3 000'

  private async created() {
    const content = await this.$api.common.getContentBySlug('/landing_price')
    if (content.data) {
      const data = content.data
      if (data.bsteps_content) {
        const content = data.bsteps_content
        if (content.three_month_price_for_address) {
          this.three_month_price_for_address = content.three_month_price_for_address
        }
        if (content.three_month_price) {
          this.three_month_price = content.three_month_price
        }
        if (content.premium_price) {
          this.premium_price = content.premium_price
        }
        if (content.premium_price_banner) {
          this.premium_price_banner = content.premium_price_banner
        }
      }
    }

  }
}
